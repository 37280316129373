import { Box, FormControl, Select, MenuItem, Divider, useMediaQuery, useTheme } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { SelectChangeEvent } from "@mui/material/Select";
import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../../../MainContext";
import AddClient from "../../Client/AddClient";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { CustomizableInputProps } from "../../../interfaces/formInputs";

const BuyerInput: React.FC<CustomizableInputProps<string> & {setValue:any, watch: any}> = ({ onChange, setValue, watch }) => {
    const [showAdder, setShowAdder] = useState(false);
    const { clients, texts, errorArr } = useContext(MainContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const buyer = watch("buyer")

    const handleChange = (event: SelectChangeEvent) => {
        setValue("buyer", event.target.value);
    };

    const openAdder = () => {
        setShowAdder((e) => !e);
    };

    useEffect(() => {
        onChange({
            target: { name: "buyer",  value: buyer, },
        });
    }, [buyer]);

	console.log(buyer)

    return (
        <Box sx={{ width: isMobile ? "92%" : "50%", margin: "10px", display: isMobile ? 'block' : '' }}>
            <Divider style={{ margin: "30px 0" }}>{texts.propertyPersonInputs.buyerTitle}</Divider>

            <FormControl
                fullWidth
                sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: isMobile ? "stretch" : "center",
                }}
                error={errorArr.includes('buyer') ? true : false}
            >
                <InputLabel id="demo-simple-select-label">{texts.propertyPersonInputs.buyer}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={buyer}
                    label="Clients"
                    onChange={handleChange}
                    sx={{
                        width: isMobile ? "100%" : "85%",
                        marginBottom: isMobile ? "10px" : "0",
                    }}
                >
                    {clients.map((client: any) => (
                        <MenuItem value={client._id}> {client.name}</MenuItem>
                    ))}
                </Select>
                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={openAdder}
                    sx={{
                        marginLeft: isMobile ? "0" : "5%",
                        alignSelf: isMobile ? "center" : "center",
                    }}
                    size="medium"
                >
                    <AddIcon />
                </Fab>
            </FormControl>
            {showAdder ? <AddClient /> : ""}
        </Box>
    );
};

export default BuyerInput;
