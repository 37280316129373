import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "./MainContext";
import { Box, Typography, Collapse, TextField, Divider, Tooltip } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PropertyFilter from "./components/Property/PropertyFilter/PropertyFilter";
import PropertyList from "./components/Property/PropertyList";
import Autocomplete from "@mui/material/Autocomplete";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import { styled, useTheme } from "@mui/material/styles";
import { useMemo } from "react";

let pervious_code = ""
interface CollapsableBoxProps {
	title: string;
	children: React.ReactNode; // Accept children as a prop
}
const drawerWidth = 440;



const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: "flex-start",
}));

const App: React.FC = () => {
	const { properties, setFilteredProperties, filteredProperties, setProperties } =
		useContext(MainContext);
	const [code, setCode] = useState(null);
	const theme = useTheme();
	const [open, setOpen] = useState(false);


	const updateProperties = (code: string | null) => {
		if (code) {
			console.log(code)
			const filtered = properties.filter((property: any) => property.code === code);
			setFilteredProperties(filtered);
		} else {
			setFilteredProperties(properties);
		}
	};


	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const options = useMemo(() => {
		return properties.map((property: any) => {
			if (property.code !== undefined) {
				return { label: property.code };
			}
		}).filter(Boolean);
	}, [properties]);

	const handleChange = (event: any, value: any) => {
		const selectedCode = value ? value.label : null;
		setCode(selectedCode); // Update local state
		updateProperties(selectedCode); // Update the filtered list
	};
	console.log(filteredProperties)


	return (
		<div className="main">
			<Box sx={{ p: 4, position: "relative" }} >
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
					<Autocomplete
						disablePortal
						options={options}
						sx={{ width: "100%" }}
						onChange={handleChange}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Codes"
								onKeyDown={(event) => {
									if (event.key === 'Enter') {
										event.preventDefault();
										const inputValue = params.inputProps.value;

										// Check for matching option
										const matchingOption = options.find((opt: any) => opt.label == inputValue);
			
										// Trigger handleChange with matching option or new value
										handleChange(null, matchingOption || inputValue);
									} else if (event.key === 'Backspace') {
										const inputValue = params.inputProps.value;
										if (inputValue === '') {
											updateProperties(null);
										}									
									}
								}}
							/>
						
						)}
					/>

					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Tooltip title="Open Filter">
							<IconButton
								color="inherit"
								aria-label="open drawer"
								edge="end"
								onClick={handleDrawerOpen}
								sx={[open && { display: "none" }, { marginLeft: "20px" }]}
							>
								<FilterAltIcon sx={{ fontSize: "35px" }} />
							</IconButton>
						</Tooltip>
					</Box>
				</Box>
				<Drawer
					sx={{
						width: drawerWidth,
						flexShrink: 0,
						"& .MuiDrawer-paper": {
							width: drawerWidth,
						},
					}}
					variant="persistent"
					anchor="right"
					open={open}
				>
					<DrawerHeader>
						<IconButton onClick={handleDrawerClose}>
							{theme.direction === "rtl" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
						</IconButton>
					</DrawerHeader>
					<Divider />
					<List>
						<ListItem disablePadding>
							<PropertyFilter full={true} setFilteredProperties={setFilteredProperties} />
						</ListItem>
					</List>
				</Drawer>
				<Box sx={{ marginTop: "50px" }}>
					<Divider style={{ margin: "30px 0" }}>Property List</Divider>
					<PropertyList />
				</Box>
			</Box>

		</div>
	);
};

export default App;
