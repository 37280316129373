import {
	Box,
	FormControlLabel,
	FormControl,
	FormLabel,
	Checkbox,
	Divider,
} from "@mui/material";
import { useContext } from "react";
import { Controller } from "react-hook-form";
import { MainContext } from "../../../MainContext";

const FilterMainInput: React.FC<{ control: any}> = ({ control }) => {
    const { keys } = useContext(MainContext);

    return (
        <Box>
            <Divider style={{ margin: "30px 0" }}>Main Information</Divider>
			<Box sx={{ display: "inline" }}>
				<FormControl
					sx={{
						padding: "20px",
						border: "1px solid #333",
						borderRadius: "5px",
						margin: "20px",
						width: "150px",
					}}
					component="fieldset"
					margin="normal"
				>
					<FormLabel component="legend">Action</FormLabel>
					<Controller
						name="action"
						control={control}
						render={({ field: { onChange, value } }) => (
							<>
								{["sell", "rent"].map((option) => (
									<FormControlLabel
										key={option}
										control={
											<Checkbox
												checked={value?.includes(option as "sell" | "rent") || false}
												onChange={(e) => {
													const newValue = e.target.checked
														? [...(value || []), option] // Add to the array if checked
														: value.filter((item: any) => item !== option); // Remove from the array if unchecked
													onChange(newValue); // Update the field's value
												}}
											/>
										}
										label={option.charAt(0).toUpperCase() + option.slice(1)}
									/>
								))}
							</>
						)}
					/>
				</FormControl>
				<FormControl
					sx={{
						padding: "20px",
						border: "1px solid #333",
						borderRadius: "5px",
						margin: "20px",
						width: "200px",
					}}
					component="fieldset"
					margin="normal"
				>
					<FormLabel component="legend">Type</FormLabel>
					<Controller
						name="type"
						control={control}
						render={({ field: { onChange, value } }) => (
							<>
								{["apartment", "house", "commercial"].map((option) => (
									<FormControlLabel
										key={option}
										control={
											<Checkbox
												//@ts-ignore
												checked={value?.includes(option) || false}
												onChange={(e) => {
													const newValue = e.target.checked
														? [...(value || []), option] // Add to the array if checked
														: value.filter((item: any) => item !== option); // Remove from the array if unchecked
													onChange(newValue); // Update the field's value
												}}
											/>
										}
										label={option.charAt(0).toUpperCase() + option.slice(1)}
									/>
								))}
							</>
						)}
					/>
				</FormControl>
				<FormControl
					sx={{
						padding: "20px",
						border: "1px solid #333",
						borderRadius: "5px",
						margin: "20px",
						width: "300px",
						maxWidth: "30vw",
					}}
					component="fieldset"
					margin="normal"
				>
					<FormLabel component="legend">Status</FormLabel>
					<Controller
						name="status"
						control={control}
						render={({ field: { onChange, value } }) => (
							<>
								{["pre-owned", "new"].map((option) => (
									<FormControlLabel
										key={option}
										control={
											<Checkbox
												//@ts-ignore
												checked={value?.includes(option) || false}
												onChange={(e) => {
													const newValue = e.target.checked
														? [...(value || []), option] // Add to the array if checked
														: value.filter((item: any) => item !== option); // Remove from the array if unchecked
													onChange(newValue); // Update the field's value
												}}
											/>
										}
										label={option.charAt(0).toUpperCase() + option.slice(1)}
									/>
								))}
							</>
						)}
					/>
				</FormControl>
				<FormControl
					sx={{
						padding: "20px",
						border: "1px solid #333",
						borderRadius: "5px",
						margin: "20px",
						width: "300px",
					}}
					component="fieldset"
					margin="normal"
				>
					<FormLabel component="legend">Building Type</FormLabel>
					<Box
						sx={{
							display: "flex", // Set display to flex
							flexWrap: "wrap", // Allow wrapping if the container's width is exceeded
							gap: "10px", // Add spacing between checkboxes
						}}
					>
						<Controller
							name="buildingType"
							control={control}
							render={({ field: { onChange, value } }) => (
								<>
									{["Stone", "Panels", "Monolith", "Bricks", "Cassette", "Wooden"].map((option) => (
										<FormControlLabel
											key={option}
											control={
												<Checkbox
													//@ts-ignore
													checked={value?.includes(option) || false}
													onChange={(e) => {
														const newValue = e.target.checked
															? [...(value || []), option] // Add to the array if checked
															: value.filter((item: any) => item !== option); // Remove from the array if unchecked
														onChange(newValue); // Update the field's value
													}}
												/>
											}
											label={option.charAt(0).toUpperCase() + option.slice(1)}
										/>
									))}
								</>
							)}
						/>
					</Box>
				</FormControl>
				<FormControl
					sx={{
						padding: "20px",
						border: "1px solid #333",
						borderRadius: "5px",
						margin: "20px",
						width: "300px",
					}}
					component="fieldset"
					margin="normal"
				>
					<FormLabel component="legend">Checkboxes</FormLabel>
					<Box
						sx={{
							display: "flex", // Set display to flex
							flexWrap: "wrap", // Allow wrapping if the container's width is exceeded
							gap: "10px", // Add spacing between checkboxes
						}}
					>
						<Controller
							name="other"
							control={control}
							render={({ field: { onChange, value } }) => (
								<>
									{keys
										?.filter((k: any) => k.type === "checkbox")
										//@ts-ignore
										.map(({ name: option }) => (
											<FormControlLabel
												key={option}
												control={
													<Checkbox
														//@ts-ignore
														checked={value?.includes(option) || false}
														onChange={(e) => {
															const newValue = e.target.checked
																? [...(value || []), option] // Add to the array if checked
																: value.filter((item: any) => item !== option); // Remove from the array if unchecked
															onChange(newValue); // Update the field's value
														}}
													/>
												}
												label={option.charAt(0).toUpperCase() + option.slice(1)}
											/>
										))}
								</>
							)}
						/>
					</Box>
				</FormControl>
			</Box>
        </Box>
    )
}

export default FilterMainInput