import React from "react";
import { AppBar, Toolbar, Button, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useContext } from "react";
//@ts-ignore
import logo from "../../logos/casa.png";
import { MainContext } from "../../MainContext";

const Navbar: React.FC = () => {
    const { texts, switchLanguage, language } = useContext(MainContext);

    return (
        <AppBar position="sticky" sx={{ mb: 4, backgroundColor: "#000231" }}>
            <Toolbar>
                {/* Logo Section */}
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" sx={{ color: "white" }}>
                        <Link
                            to="/"
                            style={{
                                color: "white",
                                textDecoration: "none",
                                display: "flex",
                                alignItems: "center",
                                maxWidth: '120px'
                            }}
                        >
                            <img style={{ width: "120px" }} src={logo} alt="Logo" />
                        </Link>
                    </Typography>
                </Box>

                {/* Navigation Buttons */}
                <Button color="inherit" component={Link} to="/" sx={{ marginRight: 2 }}>
                    {texts.navbar.home}
                </Button>
                <Button color="inherit" component={Link} to="/client" sx={{ marginRight: 2 }}>
                    {texts.navbar.client}
                </Button>
                <Button color="inherit" component={Link} to="/employee" sx={{ marginRight: 2 }}>
                    {texts.navbar.employee}
                </Button>
                <Button color="inherit" component={Link} to="/property">
                    {texts.navbar.property}
                </Button>

                {/* Language Switch Button */}
                <Button color="inherit" onClick={() => switchLanguage(language === "en" ? "arm" : "en")} sx={{ marginLeft: 2 }}>
                    {language === "en" ? "Hy" : "En"}
                </Button>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
