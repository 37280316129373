import {
	Box,
	TextField,
	FormLabel,
	InputAdornment,
	Slider,
	Divider,
	useTheme,
	useMediaQuery
} from "@mui/material";
import React, { useContext } from "react";
import { Controller } from "react-hook-form";
import { MainContext } from "../../../MainContext";

const NumbersInputs: React.FC<{
	control: any;
	setValue: any;
	watch:any
}> = ({ control, setValue, watch }) => {
	const surfaceValue = watch("surface");
	const roomsValue = watch("rooms");
	const bathroomsValue = watch("bathrooms");
	const ceilingValue = watch("ceiling");
	const floorValue = watch("floor");
	const totalFloorsValue = watch("totalFloors");
	const { texts } = useContext(MainContext)
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Box>
			<Divider style={{ margin: "30px 0" }}>{texts.numbersInput.title}</Divider>
			<Box sx={{ display: isMobile ? "block" : "flex", marginTop: "30px" }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						width: 250,
						marginRight: "50px",
					}}
				>
					{/* Number Input */}
					<FormLabel component="legend">{texts.numbersInput.propertySurface}</FormLabel>
					<Controller
						name="surface"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								type="number"
								InputProps={{
									endAdornment: <InputAdornment position="end">m²</InputAdornment>,
								}}
								onChange={(e) => {
									const value = Number(e.target.value);
									field.onChange(value); // Update form value
									if (value >= 0 && value <= 1000) {
										setValue("surface", value); // Sync slider
									}
								}}
								onBlur={() => {
									if (surfaceValue < 0) setValue("surface", 0);
									if (surfaceValue > 1000) setValue("surface", 1000);
								}}
							/>
						)}
					/>
					{/* Slider */}
					<Controller
						name="surface"
						control={control}
						render={({ field }) => (
							<Slider
								{...field}
								value={surfaceValue || 0}
								min={0}
								max={200}
								step={1}
								marks={[
									{ value: 0, label: "0 m²" },
									{ value: 100, label: "100 m²" },
									{ value: 200, label: "200 m²" },
								]}
								onChange={(_, value) => {
									field.onChange(value); // Update form value
									setValue("surface", value as number); // Sync number input
								}}
								sx={{
									"& .MuiSlider-markLabel:nth-child(4)": {
										transform: "none", // Keep labels centered
									},
								}}
							/>
						)}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						width: 250,
						marginRight: "50px",
						marginTop: isMobile ? "20px" : "0"
					}}
				>
					{/* Number Input */}
					<FormLabel component="legend">{texts.numbersInput.ceiling}</FormLabel>
					<Controller
						name="ceiling"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								type="number"
								InputProps={{
									endAdornment: <InputAdornment position="end">m</InputAdornment>,
								}}
								onChange={(e) => {
									const value = Number(e.target.value);
									field.onChange(value); // Update form value
									if (value >= 0 && value <= 1000) {
										setValue("ceiling", value); // Sync slider
									}
								}}
								onBlur={() => {
									if (ceilingValue < 0) setValue("ceiling", 0);
									if (ceilingValue > 1000) setValue("ceiling", 1000);
								}}
							/>
						)}
					/>
					{/* Slider */}
					<Controller
						name="ceiling"
						control={control}
						render={({ field }) => (
							<Slider
								{...field}
								value={ceilingValue || 0}
								min={1}
								max={5}
								step={0.05}
								marks={[
									{ value: 1, label: "1 m" },
									{ value: 2, label: "2 m" },
									{ value: 3, label: "3 m" },
									{ value: 4, label: "4 m" },
									{ value: 5, label: "5 m" },
								]}
								onChange={(_, value) => {
									field.onChange(value); // Update form value
									setValue("ceiling", value as number); // Sync number input
								}}
								sx={{
									"& .MuiSlider-markLabel:nth-child(4)": {
										transform: "none", // Keep labels centered
									},
								}}
							/>
						)}
					/>
				</Box>
			</Box>
			<Box sx={{ display: isMobile ? "block" : "flex", marginTop: "30px" }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						width: 250,
						marginRight: "50px",
						marginTop: isMobile ? "20px" : "0"
					}}
				>
					{/* Number Input */}
					<FormLabel component="legend">{texts.numbersInput.rooms}</FormLabel>
					<Controller
						name="rooms"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								type="number"
								onChange={(e) => {
									const value = Number(e.target.value);
									field.onChange(value); // Update form value
									if (value >= 0 && value <= 1000) {
										setValue("rooms", value); // Sync slider
									}
								}}
								onBlur={() => {
									if (roomsValue < 0) setValue("rooms", 0);
									if (roomsValue > 1000) setValue("rooms", 1000);
								}}
							/>
						)}
					/>

					{/* Slider */}
					<Controller
						name="rooms"
						control={control}
						render={({ field }) => (
							<Slider
								{...field}
								value={roomsValue || 0}
								min={1}
								max={4}
								step={1}
								marks={[
									{ value: 1, label: "1" },
									{ value: 2, label: "2" },
									{ value: 3, label: "3" },
									{ value: 4, label: "4+" },
								]}
								onChange={(_, value) => {
									field.onChange(value); // Update form value
									setValue("rooms", value as number); // Sync number input
								}}
								sx={{
									"& .MuiSlider-markLabel:nth-child(4)": {
										transform: "none", // Keep labels centered
									},
								}}
							/>
						)}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						width: 250,
						marginRight: "50px",
						marginTop: isMobile ? "20px" : "0"
					}}
				>
					{/* Number Input */}
					<FormLabel component="legend">{texts.numbersInput.bathrooms}</FormLabel>
					<Controller
						name="bathrooms"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								type="number"
								onChange={(e) => {
									const value = Number(e.target.value);
									field.onChange(value); // Update form value
									if (value >= 0 && value <= 1000) {
										setValue("rooms", value); // Sync slider
									}
								}}
								onBlur={() => {
									if (bathroomsValue < 0) setValue("bathrooms", 0);
									if (bathroomsValue > 1000) setValue("bathrooms", 1000);
								}}
							/>
						)}
					/>

					{/* Slider */}
					<Controller
						name="bathrooms"
						control={control}
						render={({ field }) => (
							<Slider
								{...field}
								value={bathroomsValue || 0}
								min={1}
								max={4}
								step={1}
								marks={[
									{ value: 1, label: "1" },
									{ value: 2, label: "2" },
									{ value: 3, label: "3" },
									{ value: 4, label: "4+" },
								]}
								onChange={(_, value) => {
									field.onChange(value); // Update form value
									setValue("bathrooms", value as number); // Sync number input
								}}
								sx={{
									"& .MuiSlider-markLabel:nth-child(4)": {
										transform: "none", // Keep labels centered
									},
								}}
							/>
						)}
					/>
				</Box>
			</Box>
			<Box sx={{ display: isMobile ? "block" : "flex", marginTop: "30px" }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						width: 250,
						marginRight: "50px",
						marginTop: isMobile ? "20px" : "0"
					}}
				>
					{/* Number Input */}
					<FormLabel component="legend">{texts.numbersInput.floor}</FormLabel>
					<Controller
						name="floor"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								type="number"
								onChange={(e) => {
									const value = Number(e.target.value);
									field.onChange(value); // Update form value
									if (value >= 0 && value <= 1000) {
										setValue("floor", value); // Sync slider
									}
								}}
								onBlur={() => {
									if (floorValue < 0) setValue("floor", 0);
									if (floorValue > 1000) setValue("floor", 1000);
								}}
							/>
						)}
					/>
					{/* Slider */}
					<Controller
						name="floor"
						control={control}
						render={({ field }) => (
							<Slider
								{...field}
								value={floorValue || 0}
								min={1}
								max={50}
								step={1}
								marks={[
									{ value: 1, label: "1" },
									{ value: 10, label: "10" },
									{ value: 20, label: "20" },
									{ value: 30, label: "30" },
									{ value: 40, label: "40" },
									{ value: 50, label: "50" },
								]}
								onChange={(_, value) => {
									field.onChange(value); // Update form value
									setValue("floor", value as number); // Sync number input
								}}
								sx={{
									"& .MuiSlider-markLabel:nth-child(4)": {
										transform: "none", // Keep labels centered
									},
								}}
							/>
						)}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						width: 250,
						marginRight: "50px",
						marginTop: isMobile ? "20px" : "0"
					}}
				>
					{/* Number Input */}
					<FormLabel component="legend">{texts.numbersInput.totalFloors}</FormLabel>
					<Controller
						name="totalFloors"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								type="number"
								onChange={(e) => {
									const value = Number(e.target.value);
									field.onChange(value); // Update form value
									if (value >= 0 && value <= 1000) {
										setValue("totalFloors", value); // Sync slider
									}
								}}
								onBlur={() => {
									if (totalFloorsValue < 0) setValue("totalFloors", 0);
									if (totalFloorsValue > 1000) setValue("totalFloors", 1000);
								}}
							/>
						)}
					/>
					{/* Slider */}
					<Controller
						name="totalFloors"
						control={control}
						render={({ field }) => (
							<Slider
								{...field}
								value={totalFloorsValue || 0}
								min={1}
								max={50}
								step={1}
								marks={[
									{ value: 1, label: "1" },
									{ value: 10, label: "10" },
									{ value: 20, label: "20" },
									{ value: 30, label: "30" },
									{ value: 40, label: "40" },
									{ value: 50, label: "50" },
								]}
								onChange={(_, value) => {
									field.onChange(value); // Update form value
									setValue("totalFloors", value as number); // Sync number input
								}}
								sx={{
									"& .MuiSlider-markLabel:nth-child(4)": {
										transform: "none", // Keep labels centered
									},
								}}
							/>
						)}
					/>
				</Box>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 2,
					width: 250,
					marginTop: "50px",
				}}
			>
				{/* Number Input */}
				<FormLabel component="legend">{texts.numbersInput.renovation}</FormLabel>
				{/* Slider */}
				<Controller
					name="renovation"
					control={control}
					render={({ field }) => (
						<Slider
							{...field}
							min={0}
							max={7}
							step={1}
							marks={[
								{ value: 0, label: '0'},
								{ value: 1, label: "1" },
								{ value: 2, label: "2" },
								{ value: 3, label: "3" },
								{ value: 4, label: "4" },
								{ value: 5, label: "5" },
								{ value: 6, label: "6" },
								{ value: 7, label: "7" },
							]}
							onChange={(_, value) => {
								field.onChange(value); // Update form value
								setValue("renovation", value as number); // Sync number input
							}}
							sx={{
								"& .MuiSlider-markLabel:nth-child(4)": {
									transform: "none", // Keep labels centered
								},
							}}
						/>
					)}
				/>
			</Box>
		</Box>
	);
};

export default NumbersInputs;
