import React, { useState, useEffect, useContext } from "react";
import { Box, Typography } from "@mui/material";
import PropertyInput from "./PropertyInput/PropertyInput";
import { MainContext } from "../../MainContext";

const PropertyPage: React.FC = () => {
    const { properties, setProperties, updateProperties, setFilteredProperties, filteredProperties, texts } = useContext(MainContext);
    const [loading, setLoading] = useState(false);

    // Fetch properties from the backend
    const fetchProperties = async () => {
        try {
            setLoading(true);
            setFilteredProperties(properties);
        } catch (error) {
            console.error("Error fetching properties:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProperties();
    }, []);

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom>
                {texts.propertyPage.title}
            </Typography>
            <PropertyInput full={false} />
        </Box>
    );
};

export default PropertyPage;
