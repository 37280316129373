import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import App from "./App";
import { BrowserRouter, HashRouter, Route, Router, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/NavBar";
import PropertyPage from "./components/Property/PropertyPage";
import ClientPage from "./components/Client/Client";
import axios from "axios";
import { MainContextProvider } from "./MainContext";
import ComponentPage from "./components/Property/ComponentPage";
import EditComponentPage from "./components/Property/EditComponentPage";
import EmployeePage from "./components/Employee/Employee";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MobileNavbar from "./components/Navbar/MobileNavbar";

const theme = createTheme({
	palette: {
		primary: {
			main: "#000231", // Set your primary color
		},
		secondary: {
			main: "#000231", // Set your secondary color
		},
		background: {
			default: "#f5f5f5", // Set background color
			paper: "#ffffff", // Set paper background color
		},
		text: {
			primary: "#000000", // Set primary text color
			secondary: "#757575", // Set secondary text color
		},
	},
});
const baseURL = "http://localhost:9000/api/";
axios.defaults.baseURL = process.env.REACT_APP_BACK_URL + "/api/";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<ThemeProvider theme={theme}>
		<MainContextProvider>
			<HashRouter>
				{window.innerWidth < 500 ? <MobileNavbar /> : <Navbar />}
				<CssBaseline />
				<Routes>
					<Route path="/" element={<App />} />
					<Route path="/property" element={<PropertyPage />} />
					<Route path="/client" element={<ClientPage />} />
					<Route path="/employee" element={<EmployeePage />} />
					<Route path="/component/:id" element={<ComponentPage />} />
					<Route path="/component/edit/:id" element={<EditComponentPage />} />
				</Routes>
			</HashRouter>
		</MainContextProvider>
	</ThemeProvider>
);
