import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme
} from "@mui/material";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import { MainContext } from "../../MainContext";

interface IClient {
  _id: string;
  name: string;
  contact: string;
  comment: string;
  type: string;
  associations: string[];
}

const ClientList: React.FC<{setEdit: any}> = ({setEdit}) => {
  // const [clients, setClients] = useState<IClient[]>([]);
  const { clients, updateClients, properties, clientTypes, updateClientTypes } = useContext(MainContext);
  const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Fetch clients from the backend
  // useEffect(() => {
  //   const fetchClients = async () => {
  //     try {
  //       const response = await axios.get("/client"); // Adjust endpoint as needed
  //       setClients(response.data);
  //     } catch (error) {
  //       console.error("Error fetching clients:", error);
  //     }
  //   };
  //   fetchClients();
  // }, []);

  // Handle client deletion
  const handleEdit = async (id: string) => {
    setEdit(id)
    window.scrollTo(0, 0);
  };

  return (
    <Box sx={{ mt: 4, mx: "auto", maxWidth: isMobile ? 320 : 800 }}>
      <Typography variant="h5" gutterBottom>
        Client List
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Comment</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client: any) => (
              <TableRow key={client._id}>
                <TableCell>{client.name}</TableCell>
								<TableCell>
									<ul style={{ margin: 0, paddingLeft: "20px" }}>
										{client.contact.map((contact: any, index: any) => (
											<li key={index}>{contact}</li>
										))}
									</ul>
								</TableCell>
                <TableCell>{client.comment}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleEdit(client._id)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ClientList;
