import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { IProperty } from "../../interfaces/property";
import "./ComponentPage.css";
import Modal from "@mui/material/Modal";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import CreateIcon from "@mui/icons-material/Create";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import Swal from "sweetalert2";
import { Navigate } from "react-router-dom";
import { MainContext } from "../../MainContext";
import anime from "animejs/lib/anime.es.js";
import CustomCarousel from "../CustomSlider";

const actions = [
	{ icon: <CreateIcon />, name: "Edit" },
	{ icon: <UpgradeIcon />, name: "Update status" },
];


const ComponentPage = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [property, setProperty] = useState<IProperty>();
	const [isLoading, setIsLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const handleClose = () => setOpen(false);
	const [selectedImg, setSelectedImg] = useState(0);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const { texts, clients, employees } = useContext(MainContext);
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: window.innerWidth > 500 && dimensions.width > dimensions.height ? 1000 : 700,
		height: dimensions.width < dimensions.height  ? 1000 : "auto",
		border: "none",
		boxShadow: 24,
		outline: "none",
		p: 4,
	};

	const handleOpen = (id: number) => {
		setOpen(true);
		setSelectedImg(id);
		checkImageDimensions(
			process.env.REACT_APP_BACK_URL +
				"/api/" +
				"uploads/" +
				property?.images.find((_, index) => index === selectedImg)
		);
	};
	console.log(dimensions);

	const checkImageDimensions = (url: string) => {
		const img = new Image();
		img.src = url;

		img.onload = () => {
			setDimensions({ width: img.width, height: img.height });
		};

		img.onerror = () => {
			console.error("Error loading the image");
		};
	};

	const getProperty = async () => {
		try {
			const response = await axios.get("/property/" + id);
			setProperty(response.data);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getProperty();
	}, []);

	useEffect(() => {
		anime({
			targets: ".img-container",
			translateX: {
				value: [-1000, 0],
			},
			duration: 2000,
			easing: "easeOutExpo",
		});
		anime({
			targets: ".property-description",
			translateX: {
				value: [1000, 0],
			},
			duration: 2000,
			easing: "easeOutExpo",
		});
	}, [property]);
	console.log(property);

	const handleAction = async (name: string) => {
		if (name === "Update status") {
			try {
				Swal.fire({
					title: "Are you sure?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Update status",
				}).then(async (result) => {
					if (result.isConfirmed) {
						await axios.post("/property/update-status/" + id);
						Swal.fire({
							title: "Property status updated",
							icon: "success",
						});
					}
				});
			} catch (error) {
				console.log(error);
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: `${error}`,
				});
			}
		} else if (name === "Edit") {
			// Swal.fire({
			//     title: "Are you sure?",
			//     icon: "warning",
			//     showCancelButton: true,
			//     confirmButtonColor: "#3085d6",
			//     cancelButtonColor: "#d33",
			//     confirmButtonText: "Update status",
			// }).then(async (result) => {
			//     if (result.isConfirmed) {
			// 	}
			// });
			navigate(`/component/edit/${id}`);
		}
	};

	console.log(property?.images);
	return (
		<>
			{isLoading ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100vh",
					}}
				>
					<CircularProgress />
				</div>
			) : (
				<div className="main-component">
					<Modal
						open={open}
						onClose={handleClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<Box sx={style}>
							<img
								src={
									process.env.REACT_APP_BACK_URL +
									"/api/" +
									"uploads/" +
									property?.images.find((_, index) => index === selectedImg)
								}
							/>
						</Box>
					</Modal>
					{/* Left side: Image Carousel */}
					<div className="img-container">
						<CustomCarousel>
							{property?.images.map((image, index) => {
								return (
									<img
										key={index}
										src={process.env.REACT_APP_BACK_URL + "/api/" + "uploads/" + image}
										alt={image}
										onClick={() => handleOpen(index)}
									/>
								);
							})}
						</CustomCarousel>
						<div className="mini-images">
							{property?.images.map((image, index) => {
								return (
									<img
										key={index}
										src={process.env.REACT_APP_BACK_URL + "/api/" + "uploads/" + image}
										alt={image}
										onClick={() => handleOpen(index)}
									/>
								);
							})}
						</div>
					</div>

					<div className="property-description">
						{Object.entries(property || {})
							.filter(([k, _]) => k === "code")
							.map(([k, v]) => (
								<div className="code">
									<h2 className="">
										{texts.watch[k]}: {v}
									</h2>
								</div>
							))}
						{Object.entries(property || {})
							.filter(
								([key, _]) =>
									key !== "__v" &&
									key !== "_id" &&
									key !== "images" &&
									key !== "createdAt" &&
									key !== "updatedAt" &&
									key !== "code" &&
									key !== "buyer"
							)
							.map(([key, value]) => (
								<div key={key} className="description-item">
									<strong className="description-key">{texts.watch[key]}: </strong>
									{typeof value !== "object" ? (
										<span className="description-value">
											{`${key}` === "owner"
												? `${[...clients, ...employees].find((e) => e._id === value)?.name} (${
														[...clients, ...employees].find((e) => e._id === value)?.contact
												  })` || "Unknown Owner"
												: `${key}` === "buyer"
												? `${[...clients, ...employees].find((e) => e._id === value)?.name} (${
														[...clients, ...employees].find((e) => e._id === value)?.contact
												  })` || "Unknown Owner"
												: `${key}` === "agent"
												? `${[...clients, ...employees].find((e) => e._id === value)?.name} (${
														[...clients, ...employees].find((e) => e._id === value)?.contact
												  })` || "Unknown Owner"
												: texts.watch[value]
												? texts.watch[value]
												: value}
										</span>
									) : (
										<div className="nested-object">
											{Object.entries(value || {}).map(([nestedKey, nestedValue]) => (
												<div key={nestedKey} className="nested-item">
													<strong className="nested-key">
														{texts.watch[nestedKey] ? texts.watch[nestedKey] : nestedKey}:{" "}
													</strong>
													{typeof nestedValue === "object" ? (
														<div>
															{Object.entries(nestedValue ? nestedValue : {}).map(
																([key2, value2]) => (
																	<p>
																		{key2}: {value2}
																	</p>
																)
															)}
														</div>
													) : (
														<span>
															{(typeof nestedValue !== "object" &&
																typeof nestedValue === "string") ||
															typeof nestedValue === "number"
																? nestedValue
																: ""}
														</span>
													)}
												</div>
											))}
										</div>
									)}
								</div>
							))}
					</div>
				</div>
			)}
			<Box sx={{ height: isMobile ? 10 : 120, transform: "translateZ(0px)", flexGrow: 1 }}>
				<SpeedDial
					ariaLabel="SpeedDial basic example"
					sx={{
						position: isMobile ? "absolute" : "absolute",
						bottom: isMobile ? 40 : 40,
						right: isMobile ? 15 : 26,
					}}
					icon={<SpeedDialIcon />}
				>
					{actions.map((action) => (
						<SpeedDialAction
							key={action.name}
							icon={action.icon}
							tooltipTitle={action.name}
							onClick={() => handleAction(action.name)}
						/>
					))}
				</SpeedDial>
			</Box>
		</>
	);
};

export default ComponentPage;
