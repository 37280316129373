import { Box, Button, Card, CardContent, CardMedia, Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const UploadInput: React.FC<{
	imagePreviews: any;
	handleImageChange: any;
	handleRemoveImage: any;
	handleDocumentChange: any;
	imagePreviews_doc: any;
}> = ({ imagePreviews, handleImageChange, handleRemoveImage, handleDocumentChange, imagePreviews_doc }) => {
	
	return (
		<Box>
			<Button variant="outlined" component="label" sx={{ mt: 2, mb: 3 }}>
				+ Upload Images
				<input type="file" hidden multiple accept="image/*" onChange={handleImageChange} />
			</Button>
			<Grid container spacing={2} sx={{ mb: 3 }}>
				{imagePreviews.map((src:any, index: number) => (
					<Grid item xs={4} key={index}>
						<Card>
							<CardMedia component="img" height="140" image={src} alt={`Preview ${index + 1}`} />
							<CardContent>
								<IconButton color="secondary" onClick={() => handleRemoveImage(index, src.split("/").pop())}>
									<DeleteIcon />
								</IconButton>
							</CardContent>
						</Card>
					</Grid>
				))}
			</Grid>
			<Button variant="outlined" color="secondary" component="label" sx={{ mt: 2, mb: 2 }}>
				+ Upload Documents
				<input type="file" hidden multiple accept="*" onChange={handleDocumentChange} />
			</Button>
			<Box sx={{ mb: 5, mt: 2 }}>
				{imagePreviews_doc.map((fileName: string, index: number) => {
					return (
						<div>
							{index + 1}. {fileName}
						</div>
					);
				})}
			</Box>
		</Box>
	);
};

export default UploadInput;
