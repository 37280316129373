import React, { useState, useEffect, useContext } from "react";
import {
	Box,
	TextField,
	MenuItem,
	Button,
	Typography,
	Autocomplete,
	InputAdornment,
	Chip,
	useTheme,
	useMediaQuery
} from "@mui/material";
import axios from "axios";
import { MainContext } from "../../MainContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";

interface IFormData {
	name: string;
	contact: string[];
	comment: string;
	type: string[];
	associations: string[];
}

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

const AddClient = () => {
	const [newType, setNewType] = useState("");
	const [values, setValues] = useState<string[]>([]);
	const [inputValue, setInputValue] = useState("");
	const [formData, setFormData] = useState<IFormData>({
		name: "",
		contact: [],
		comment: "",
		type: [],
		associations: [], // Initialize as an empty array
	});
	const { updateClients, properties, clientTypes, updateClientTypes } = useContext(MainContext);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	// Handle form submission
	const handleSubmit = async () => {
		try {
			const response = await axios.post("/client", formData); // Adjust endpoint as needed
			Swal.fire({
				title: "Client added successfully",
				icon: "success",
			});
			setFormData({
				name: "",
				contact: [],
				comment: "",
				type: [],
				associations: [],
			});
			updateClients();
		} catch (error) {
			console.error("Error creating client:", error);
			alert("Failed to create client.");
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === "Enter" && inputValue.trim()) {
			const updatedValues = [...values, inputValue.trim()];
			setValues(updatedValues);
			setFormData((prev) => ({ ...prev, contact: updatedValues }));
			setInputValue("");
		}
	};

	const handleDelete = (valueToDelete: string) => {
		const updatedValues = values.filter((value) => value !== valueToDelete);
		setValues(updatedValues);
		setFormData((prev) => ({ ...prev, contact: updatedValues }));
	};

	console.log(formData.type);
	return (
		<Box
			component="form"
			sx={{ maxWidth: isMobile ? 320 :400, mx: "auto", mt: 4, p: 4, border: "1px solid #ccc", borderRadius: 2 }}
		>
			<Typography variant="h5" gutterBottom>
				Create Client
			</Typography>
			<TextField
				label="Name"
				name="name"
				value={formData.name}
				onChange={handleChange}
				fullWidth
				required
				margin="normal"
			/>
			<Box sx={{ marginTop: "10px" }}>
				<TextField
					label="Enter contact"
					variant="outlined"
					value={inputValue}
					onChange={(e) => setInputValue(e.target.value)}
					onKeyDown={handleKeyDown}
					fullWidth
				/>
				<Box mt={2}>
					{values.map((value) => (
						<Chip
							key={value}
							label={value}
							onDelete={() => handleDelete(value)}
							style={{ margin: "5px" }}
						/>
					))}
				</Box>
			</Box>
			<TextField
				label="Comment"
				name="comment"
				value={formData.comment}
				onChange={handleChange}
				fullWidth
				multiline
				margin="normal"
			/>
			<Button
				type="button"
				onClick={handleSubmit}
				variant="contained"
				color="primary"
				fullWidth
				sx={{ mt: 2 }}
			>
				Create Client
			</Button>
		</Box>
	);
};

export default AddClient;
