import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	IconButton,
	useMediaQuery,
	useTheme
} from "@mui/material";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { MainContext } from "../../MainContext";

interface IEmployee {
	_id: string;
	name: string;
	contact: string;
	comment: string;
	type: string;
}

const EmpolyeeList: React.FC<{ setEdit: any }> = ({ setEdit }) => {
	const { employees } = useContext(MainContext);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const handleEdit = async (id: string) => {
		setEdit(id);
		window.scrollTo(0, 0);
	};

	return (
		<Box sx={{ mt: 4, mx: "auto", maxWidth: isMobile ? 320 : 800 }}>
			<Typography variant="h5" gutterBottom>
				Employee List
			</Typography>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Contact</TableCell>
							<TableCell>Comment</TableCell>
							<TableCell>Type</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{employees.map((employee: any) => (
							<TableRow key={employee._id}>
								<TableCell>{employee.name}</TableCell>
								<TableCell>
									<ul style={{ margin: 0, paddingLeft: "20px" }}>
										{employee.contact.map((contact: any, index: any) => (
											<li key={index}>{contact}</li>
										))}
									</ul>
								</TableCell>
								<TableCell>{employee.comment ? employee.comment : "No Comment"}</TableCell>
								<TableCell>{employee.type}</TableCell>
								<TableCell>
									<IconButton onClick={() => handleEdit(employee._id)}>
										<EditIcon />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default EmpolyeeList;
