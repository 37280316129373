import {
	Box,
	TextField,
    Divider,
    Select,
	InputAdornment,
	MenuItem,
} from "@mui/material";
import { useContext } from "react";
import { Controller } from "react-hook-form";
import { MainContext } from "../../../MainContext";

const DescriptionsInput: React.FC<{ register: any, control: any }> = ({ register, control }) => {
	const {texts, errorArr} = useContext(MainContext)

    return (
        <Box>
			<Divider style={{ margin: "30px 0" }}>{texts.descriptionInput.title}</Divider>
			<TextField
				slotProps={{ inputLabel: { shrink: true } }} 
				label={texts.descriptionInput.description}
				error={errorArr.includes("description") ? true : false}
				multiline
				rows={4}
				fullWidth
				margin="normal"
				{...register("description", { required: true })}
			/>
			<TextField
				slotProps={{ inputLabel: { shrink: true } }} 
				label={texts.descriptionInput.price}
				error={errorArr.includes("price") ? true : false}
				margin="normal"
				type="number"
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Controller
								name="currency"
								control={control}
								defaultValue="AMD"
								render={({ field }) => (
									<Select {...field}>
										<MenuItem value="AMD">Դ</MenuItem>
										<MenuItem value="USD">$</MenuItem>
										<MenuItem value="EUR">€</MenuItem>
									</Select>
								)}
							/>
						</InputAdornment>
					),
				}}
				{...register("price", { required: true })}
			/>{" "}
        </Box>
    )
}

export default DescriptionsInput