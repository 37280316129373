import React, { useState, useEffect, useContext } from "react";
import { Box, Button, CircularProgress, Divider } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";
import { MainContext } from "../../../MainContext";
import FilterMainInput from "./FilterMainInput";
import FilterNumbersInput from "./FilterNumbersInput";
import { ILocation } from "../../../interfaces/location";



interface FormInputs {
	description: string;
	price: number;
	action: Array<"rent" | "sell">;
	type: Array<"apartment" | "house" | "commercial">;
	buildingType: Array<"Stone" | "Panels" | "Monolith" | "Bricks" | "Cassette" | "Wooden">;
	status: Array<"pre-owned" | "new">;
	propertyStatus: Array<"active" | "stopped" | "sold">;
	surface: {
		from: number;
		to: number;
	};
	rooms: {
		from: number;
		to: number;
	};
	images: FileList;
	location: ILocation;
	currency: string;
	bathrooms: {
		from: number;
		to: number;
	};
	ceiling: {
		from: number;
		to: number;
	};
	renovation: {
		from: number;
		to: number;
	};
	floor: {
		from: number;
		to: number;
	};
	totalFloors: {
		from: number;
		to: number;
	};
	other: any;
}


const PropertyFilter: React.FC<{ full: boolean; setFilteredProperties: any }> = ({
	full,
	setFilteredProperties,
}) => {
	const { properties, setProperties, updateProperties } = useContext(MainContext);

	const [imagePreviews, setImagePreviews] = useState<string[]>([]);
	const [imageFiles, setImageFiles] = useState<File[]>([]);
	const [imagePreviews_doc, setImagePreviews_doc] = useState<string[]>([]);
	const [imageFiles_doc, setImageFiles_doc] = useState<File[]>([]);
	const [loading, setLoading] = useState(false);
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
		getValues,
		control,
		watch,
	} = useForm<FormInputs>({
		defaultValues: {
			action: [],
			type: [],
			buildingType: [],
			status: [],
			propertyStatus: [],
		},
	});


	const watched = watch();

	const removeFilters = () => {
		reset();
	};

	useEffect(() => {
		const filteredProperties = properties.filter((property: any) => {
			let foundProperty = true;

			Object.keys(watched).forEach((key) => {
				//@ts-ignore
				const inputValue = watched[key];
				if (Array.isArray(inputValue) && inputValue?.length) {
					const res = inputValue.includes(property[key]);
					if (!res) {
						foundProperty = false;
					}
				} else if (typeof inputValue === "object" && "from" in inputValue && "to" in inputValue) {
					const res = property[key] >= inputValue.from && property[key] <= inputValue.to;
					if (!res) {
						foundProperty = false;
					}
				}
			});

			return foundProperty;
		});
		setFilteredProperties(filteredProperties);
	}, [JSON.stringify(watched)]);

	return (
		<Box component="form" sx={{ p: 3, width: "440px" }}>

			<FilterMainInput control={control} />

			<Divider style={{ margin: "30px 0" }}>Location</Divider>

			<FilterNumbersInput control={control} />

			<Button
				sx={{
					width: "400px"
				}}
				variant="contained"
				color="primary"
				disabled={loading}
				onClick={() => removeFilters()}
			>
				{loading ? <CircularProgress size={24} /> : "Remove Filters"}
			</Button>
		</Box>
	);
};

export default PropertyFilter;
