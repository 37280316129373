import React, { useState, useEffect, useContext } from "react";
import {
	Box,
	TextField,
	MenuItem,
	Button,
	Typography,
	Autocomplete,
	InputAdornment,
	Chip,
	useMediaQuery,
	useTheme
} from "@mui/material";
import axios from "axios";
import { MainContext } from "../../MainContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";

interface IFormData {
	name: string;
	contact: string[];
	comment: string;
}


const AddClient = () => {
	const [newType, setNewType] = useState("");
	const [values, setValues] = useState<string[]>([]);
	const [inputValue, setInputValue] = useState('');
	const [formData, setFormData] = useState<IFormData>({
		name: "",
		contact: [],
		comment: "",
	});
	const { employeeTypes, updateEmployeeTypes, updateEmployee } = useContext(MainContext);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	// Handle form submission
	const handleSubmit = async () => {
		try {
			const response = await axios.post("/employee", formData); // Adjust endpoint as needed
			console.log("Agent created:", response.data);
			setFormData({
				name: "",
				contact: [],
				comment: "",
			});
			updateEmployee();
		} catch (error) {
			console.error("Error creating agent:", error);
			alert("Failed to create agent.");
		}
	};



	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === "Enter" && inputValue.trim()) {
			const updatedValues = [...values, inputValue.trim()];
			setValues(updatedValues);
			setFormData((prev) => ({ ...prev, contact: updatedValues }));
			setInputValue("");
		}
	};
	console.log(formData)
	const handleDelete = (valueToDelete: string) => {
		const updatedValues = values.filter((value) => value !== valueToDelete);
		setValues(updatedValues);
		setFormData((prev) => ({ ...prev, contact: updatedValues }));
	};

	return (
		<Box
			component="form"
			sx={{ maxWidth: isMobile ? 320 : 400, mx: "auto", mt: 4, p: 4, border: "1px solid #ccc", borderRadius: 2 }}
		>
			<Typography variant="h5" gutterBottom>
				Create Employee
			</Typography>
			<TextField
				label="Name"
				name="name"
				value={formData.name}
				onChange={handleChange}
				fullWidth
				required
				margin="normal"
			/>
			<Box sx={{marginTop: "10px"}}>
				<TextField
					label="Enter contact"
					variant="outlined"
					value={inputValue}
					onChange={(e) => setInputValue(e.target.value)}
					onKeyDown={handleKeyDown}
					fullWidth
				/>
				<Box mt={2}>
					{values.map((value) => (
						<Chip
							key={value}
							label={value}
							onDelete={() => handleDelete(value)}
							style={{ margin: '5px' }}
						/>
					))}
				</Box>
			</Box>
			<TextField
				label="Comment"
				name="comment"
				value={formData.comment}
				onChange={handleChange}
				fullWidth
				multiline
				margin="normal"
			/>

			<Button
				type="button"
				onClick={handleSubmit}
				variant="contained"
				color="primary"
				fullWidth
				sx={{ mt: 2 }}
			>
				Create Employee
			</Button>
		</Box>
	);
};

export default AddClient;
