import ClientList from "./ClientList";
import AddClient from "./AddClient";
import { useContext, useEffect, useState } from "react";
import { MainContext } from "../../MainContext";
import EditClient from "./EditClient";


const ClientForm = () => {
  const [edit, setEdit] = useState()

  return (
    <>
      {edit ? 
        <EditClient client_id={edit} setEdit={setEdit} />
        :
        <AddClient />
      }
      <ClientList setEdit={setEdit} />
    </>
  );
};

export default ClientForm;
