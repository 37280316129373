import {
	Box,
	FormControl,
	Select,
	MenuItem,
	Divider,
	useMediaQuery,
	useTheme,
	TextField,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { SelectChangeEvent } from "@mui/material/Select";
import { useContext, useEffect, useState } from "react";
import { MainContext } from "../../../MainContext";
import AddClient from "../../Client/AddClient";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { CustomizableInputProps } from "../../../interfaces/formInputs";

const OwnerInput: React.FC<
	CustomizableInputProps<{
		name: string;
		contact: string;
		comment: string;
	}> & {watch: any, setValue: any}
> = ({ onChange, watch, setValue }) => {
	// const [owner, setOwner] = useState({
	// 	name: "",
	// 	contact: "",
	// 	comment: "",
	// });
	const [showAdder, setShowAdder] = useState(false);
	const { clients, texts, errorArr } = useContext(MainContext);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const owner = watch("owner")

	useEffect(() => {
		onChange({
			target: { name: "owner", value: owner },
		});
	}, [owner]);

	return (
		<Box component="form" sx={{ display: isMobile ? "block" : "", margin: "10px" }}>
			<Divider style={{ margin: "30px 0" }}>{texts.propertyPersonInputs.ownerTitle}</Divider>

			<TextField
				label="Name"
				name="name"
				value={owner?.name || ""}
				onChange={(e) => setValue("owner.name", e.target.value )}
				fullWidth
				required
			/>

			<TextField
				label="Contact"
				name="contact"
				value={owner?.contact || ""}
				onChange={(e) => setValue("owner.contact", e.target.value)}
				fullWidth
				required
				margin="normal"
			/>

			<TextField
				label="Contact"
				name="contact"
				value={owner?.comment || ""}
				onChange={(e) => setValue("owner.comment", e.target.value)}
				fullWidth
				required
				multiline
				margin="normal"
			/>

			{showAdder ? <AddClient /> : ""}
		</Box>
	);
};

export default OwnerInput;
