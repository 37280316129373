import React, { useState, useEffect, useContext } from "react";
import { Box, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import axios from "axios";
import { IProperty } from "../../interfaces/property";
import { MainContext } from "../../MainContext";
import { useNavigate } from "react-router-dom";
import Rating from "@mui/material/Rating";
import anime from "animejs/lib/anime.es.js";

const PropertyList: React.FC = () => {
	const navigate = useNavigate();
	const { texts , filteredProperties} = useContext(MainContext);

	const goToComponent = (id: string) => {
		navigate(`/component/${id}`);
    };
    
    // useEffect(() => {
    //     anime({
    //       targets: ".property-card",
    //       opacity: [0, 1],
    //       translateY: [50, 0],
    //       duration: 2000,
    //       easing: "easeOutExpo",
    //       delay: anime.stagger(200),
    //     });
	//   }, [filteredProperties]);
	
	console.log(filteredProperties);

	return (
		<Grid container spacing={4}>
			{filteredProperties.map((property: IProperty) => (
				<Grid item xs={12} sm={6} md={4} key={property._id}>
					<Card
						onClick={() => goToComponent(property._id)}
                        sx={{ "&:hover": { cursor: "pointer" } }}
                        className="property-card"
					>
						{property.images[0] && (
							<CardMedia
								component="img"
								height="200px"
								sx={{ height: "25vh" }}
								image={process.env.REACT_APP_BACK_URL + "/api/" + "uploads/" + property.images[0]}
								alt={property.description}
							/>
						)}
						<CardContent>
							<Box sx={{ display: "flex" }}>
								<Typography variant="h6">
									{property.rooms} Bedroom {property.action} for {property.action} on{" "}
									{property.location?.street}
								</Typography>
								<svg
									fill={property.propertyStatus}
									width="64px"
									height="64px"
									viewBox="0 0 256 256"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
									<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
									<g id="SVGRepo_iconCarrier">
										{" "}
										<circle cx="127" cy="129" r="81" fill-rule="evenodd"></circle>{" "}
									</g>
								</svg>
							</Box>
							<Typography variant="body2" color="text.secondary">
								{texts.propertyList.price}: {property.price} {property.currency}
							</Typography>
							<Typography variant="body2" color="text.secondary">
								{texts.propertyList.uploaded}: {new Date(property.createdAt).toLocaleDateString()}
							</Typography>
							<Typography
								variant="body2"
								color="text.secondary"
								sx={{ display: "flex", alignItems: "center" }}
							>
								{texts.propertyList.renovation}:{" "}
								<Rating
									name="read-only"
									value={property.renovation}
									readOnly
									max={7}
									sx={{ marginLeft: "10px" }}
								/>
							</Typography>
						</CardContent>
					</Card>
				</Grid>
			))}
		</Grid>
	);
};

export default PropertyList;
