import { useState } from "react";
import AddEmployee from "./AddEmployee";
import EmpolyeeList from "./EmployeeList";
import EditEmployee from "./EditEmployee";

const EmployeePage = () => {
	const [edit, setEdit] = useState();

	return (
		<>
			{edit ? <EditEmployee employee_id={edit} setEdit={setEdit} /> : <AddEmployee />}
			<EmpolyeeList setEdit={setEdit} />
		</>
	);
};

export default EmployeePage;
