import { createContext, useEffect, useState } from "react";
import axios from "axios";
import { texts } from "./texts";

export const MainContext = createContext();

export const MainContextProvider = ({ children }) => {
    const [properties, setProperties] = useState([]);
    const [clients, setClients] = useState([]);
    const [employees, setEmployee] = useState([]);
    const [employeeTypes, setEmployeeTypes] = useState([]);
    const [clientTypes, setClientTypes] = useState([]);
    const [keys, setKeys] = useState([]);
    const [newCode, setNewCode] = useState();
    const [filteredProperties, setFilteredProperties] = useState([]);
    const [preLocationValues, setPreLocationValues] = useState({});
    const [language, setLanguage] = useState("arm");
    const [errorArr, setErrorArr] = useState([])

    const switchLanguage = (lang) => {
        setLanguage(lang);
    };

    const flattenTexts = () => {
        const newObj = { ...JSON.parse(JSON.stringify(texts)) };
        Object.entries(texts).map(([key, value]) => {
            console.log(key);
            Object.entries(value).map(([key1, value1]) => {
                console.log(key1);
                console.log(texts[key], texts[key][key1])

                // console.log(texts[key], key, key1, value1, language)
                newObj[key][key1] = value1[language];
            });
        });
        return newObj;
    };

    const updateProperties = async () => {
        const response = await axios.get("/property"); // Replace with your API endpoint
        setProperties(response.data.reverse());
        setFilteredProperties(response.data);

        const response1 = await axios.get("/property/new-code"); // Replace with your API endpoint
        setNewCode(response1.data.code);
    };

    const updateClients = async () => {
        const res = await axios.get("/client");
        setClients(res.data);
    };

    const updateClientTypes = async () => {
        const res = await axios.get("/client/get-types");
        setClientTypes(res.data);
    };

    const updateEmployee = async () => {
        const res = await axios.get("/employee/");
        setEmployee(res.data);
    };

    const updateEmployeeTypes = async () => {
        const res = await axios.get("employee/get-types");
        setEmployeeTypes(res.data);
    };

    const updateKeys = async () => {
        const res = await axios.get("/value"); // Replace with your API endpoint
        setKeys(res.data.values);
    };

    const updatePreLocationValues = async () => {
        const res = await axios.get("/pre-location/"); // Replace with your API endpoint
        setPreLocationValues(res.data);
    };

    useEffect(() => {
        updateKeys();
        updateClients();
        updateProperties();
        updateClientTypes();
        updateEmployee();
        updateEmployeeTypes();
        updatePreLocationValues();
    }, []);

    return (
        <MainContext.Provider
            value={{
                properties,
                clients,
                keys,
                filteredProperties,
                clientTypes,
                employees,
                employeeTypes,
                updateClientTypes,
                updateEmployee,
                updateClients,
                updateKeys,
                updateProperties,
                newCode,
                setProperties,
                setFilteredProperties,
                updateEmployeeTypes,
                updatePreLocationValues,
                preLocationValues,
                texts: flattenTexts(),
                language,
                switchLanguage,
                setErrorArr,
                errorArr
            }}
        >
            {children}
        </MainContext.Provider>
    );
};
