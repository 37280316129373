import {
	Box,
	TextField,
	FormLabel,
	InputAdornment,
	Slider,
	Divider,
	FormControl,
	Checkbox,
	FormControlLabel,
	useMediaQuery,
	useTheme
} from "@mui/material";
import { Controller } from "react-hook-form";
import NewProp from "../NewProp";
import { useContext } from "react";
import { MainContext } from "../../../MainContext";

const PropertiesInput: React.FC<{ register: any; control: any }> = ({ register, control }) => {
	const { keys, texts } = useContext(MainContext);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	
	return (
		<Box>
			<Divider style={{ margin: "30px 0" }}>{texts.propertiesInput.title}</Divider>
			<NewProp />
			<Box sx={{ display: "flex", flexDirection: isMobile ? 'column' : "" }}>
				<div
					style={{
						padding: "20px",
						border: "1px solid #333",
						borderRadius: "5px",
						marginRight: "15px",
					}}
					className="checkboxes"
				>
					<FormLabel>Checkboxes</FormLabel>
					<br />
					<hr style={{ marginBottom: "20px", marginTop: "10px" }} />
					{keys
						?.filter((k: any) => k.type === "checkbox")
						.map((key: any) => {
							return (
								<FormControl component="fieldset" margin="normal">
									<Controller
										name={`other.${key.name}`}
										control={control}
										render={({ field }) => (
											<FormControlLabel
												control={
													<Checkbox
														{...field}
														checked={field.value || false}
													/>
												}
												label={key.name}
											/>
										)}
									/>
								</FormControl>
							);
						})}
				</div>
				<div
					style={{
						padding: "20px",
						border: "1px solid #333",
						borderRadius: "5px",
						marginTop: isMobile ? "30px" : ''
					}}
					className="strings"
				>
					<FormLabel>Strings</FormLabel>
					<br />
					<hr style={{ marginBottom: "20px", marginTop: "10px" }} />
					{keys
						?.filter((k: any) => k.type === "string")
						.map((key: any) => {
							return (
								<TextField
									slotProps={{ inputLabel: { shrink: true } }} 
									sx={{ margin: "15px 15px 0px 0px" }}
									label={key.name}
									margin="normal"
									{...register(`other.${key.name}`)}
								/>
							);
						})}
				</div>
			</Box>
		</Box>
	);
};

export default PropertiesInput;
